import * as React from "react"
import Layout from "../components/Layout"
import { Row } from "react-bootstrap"
import LinkCard from "../components/LinkCard"
import "../scss/base.css"

const IndexPage = () => {
    return (
        <Layout pageTitle="Brand Assets">
            <Row className="mt-5 mb-5 justify-content-center">
                <LinkCard to="/branding" title="Branding" />
                <LinkCard to="/logos" title="Logo"  />
                <LinkCard to="/awards" title="Awards" />
                <LinkCard to="/conference" title="Conference" />
                <LinkCard to="/events" title="Events" />
                <LinkCard to="/members" title="Members" />
                <LinkCard to="/leaders" title="Leaders" />
                <LinkCard to="/partners" title="Partners" />
            </Row>
        </Layout>
    )
}

export default IndexPage
